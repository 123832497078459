<template>
  <div :class="{ 'colapsed-header': isColapsedBlock }">
    <colapse-button
      :isColapsedBlock="isColapsedBlock"
      @click="isColapsedBlock = !isColapsedBlock"
    />
    <div
      v-if="!isColapsedBlock"
      class="head-and-filters js-head-and-filters"
      :style="`right: ` + rightHeadPosition"
    >
      <header-page :title="'Main Partners'" :hasBackLink="false" />

      <div class="filters-container">
        <div class="filters-block">
          <div class="search">
            <input-text v-model="search_text" />
            <div
              v-if="isShowClear"
              class="main-button lg ml-15"
              @click="clearFilters"
            >
              <div>Clear</div>
            </div>
          </div>
          <div class="button">
            <div class="main-button lg orange" @click="isVisibleFilters = true">
              <div class="icon" v-html="Filter"></div>
              <div>Filters</div>
            </div>
          </div>
        </div>
        <div class="selected-filters" v-if="selectedFilters.length">
          <div
            class="selected-filters-groups"
            v-for="(group, key) in filterGroups"
            :key="key"
          >
            <div class="selected-filters-groups-item">
              <div
                class="icon"
                @click="removeFilterGroup(key)"
                v-html="Cross"
              ></div>
              <div class="text">{{ group[0].groupName }}</div>
              <div class="selected-filters-groups-item-sub-items">
                <template v-for="groupItem in group" :key="groupItem.text">
                  <div
                    v-if="!isArray(groupItem.text)"
                    class="selected-filters-item"
                  >
                    <div
                      class="icon"
                      @click="removeFilter(groupItem.id)"
                      v-html="Cross"
                    ></div>
                    <div class="text">
                      {{ groupItem.label }} {{ groupItem.text }}
                    </div>
                  </div>

                  <div
                    v-else
                    class="selected-filters-item"
                    v-for="groupItemArray in groupItem.text"
                    :key="groupItemArray"
                  >
                    <div
                      class="icon"
                      @click="
                        removeFilterArrayItem(groupItem.id, groupItemArray)
                      "
                      v-html="Cross"
                    ></div>
                    <div class="text">{{ groupItemArray }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <grid
      v-model:columns="columns"
      v-model="rows"
      v-model:selectedRows="selectedRows"
      v-model:options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      :listTabs="listTabs"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewEntity"
      @saveRow="saveEntity"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
      @deleteSelected="deleteSelected"
      @exportSelected="exportSelected"
      @changedColumnsParams="changedColumnsParams"
    />
    <set-filters
      v-if="isVisibleFilters"
      v-model="isVisibleFilters"
      :setFilterComponent="setFilterComponent"
      :filters="filters"
      @setFilters="setFilters"
    />
  </div>
</template>

<style lang="scss">
.partner-group-block {
  border: 1px solid #dddae9;
  border-radius: 15px;
  padding: 10px 15px 20px 15px;
  margin-bottom: 20px;
}
</style>

<script>
import { shallowRef } from 'vue'
import SetFilterComponent from '@/components/partner/SetFilters/SetFiltersPartners'
import { fetchData, fetchCSV, urls } from '@/utils/urls.js'
import { listMixin } from '@/utils/list-mixin'
import { hasAccess, permissions } from '@/utils/permissions'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'

export default {
  name: 'MainPartners',
  mixins: [listMixin],

  data() {
    let colsKeyUrlIndex = 0
    return {
      options: new GrigOptions({
        isCardEditable: true,
        pathToEdit: 'main-partner',
        isSetColumns: false,
        isEditable: hasAccess(permissions.update_partner),
        isDelete: false, //hasAccess(permissions.delete_partners),
        isAdd: false,//hasAccess(permissions.create_partner),
        isExportToCSV: false, //hasAccess(permissions.export_partners_to_csv),
        addButtonText: 'Add Partner',
        getDeleteContent: async (rows) => {
          if (!rows) return ``

          let getHTML = (params) => {
            let stringHTML = ''
            for (const key in params) {
              if (typeof params[key] === 'object') {
                stringHTML += `
                  <div class="mt-10">
                    <span class="delete-label">${key.replaceAll(
                      '_',
                      ' '
                    )}</span>
                  </div>`

                for (const keyChild in params[key]) {
                  stringHTML += `
                      <div>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• ${params[key][keyChild]}</span>
                      </div>`
                }
              } else {
                stringHTML += `
                  <div class="mt-10">
                    <span class="delete-label">${key.replaceAll(
                      '_',
                      ' '
                    )}</span>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• ${params[key]}</div>
                  </div>`
              }
            }

            return stringHTML
          }

          let idsQuery = rows
            .map((row) => {
              return `partner_ids=${row.id}`
            })
            .join('&')
          let result = ''
          let infoList = await fetchData(
            urls.deleteMainPartnerInfo,
            'GET',
            null,
            idsQuery
          )
          for (let info of infoList)
            result += `<div class="partner-group-block">${getHTML(info)}</div>`

          return result
        },
      }),

      columns: [
        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'id',
          text: 'Partner ID',
          positionDefault: colsKeyUrlIndex,
          width: '80px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'company_name',
          text: 'Company name',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value, row, options }) => {
            if (value)
              return `<a href="${
                window.location.origin + '/' + options.pathToEdit + '/' + row.id
              }" target="_blank">${value}</a>`
            return value
          },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'lbp_company_name',
          text: 'Company name LBP',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value, row }) => {
            if (value)
              return `<a href="${window.location.origin}/link-builder-partner/${row.lbp_id}" target="_blank">${value}</a>`
            return value
          },
        }),
      ],
      per_page: 100,
      newRowComponent: shallowRef(/*NewRowEntity*/ null),
      setFilterComponent: shallowRef(SetFilterComponent),
      listKey: 'main-partners',
    }
  },

  created() {
    this.setDefaultColumnsParams() // 1
    this.setMetaDataColumnsParams() // 2
    this.setDataFromQueryParams() // 3
    this.fetchData()
  },

  methods: {
    saveNewEntity(entity) {
      this.setNewEntity(entity)
      this.addRow()
    },

    async editRow(entity) {
      this.$store.commit('setIsLoading', true)
      const id = entity.id
      delete entity.id

      let result = await fetchData(
        urls.mainPartners + `/${id}`,
        'POST',
        null,
        this.entityQuery(entity)
      )
      if (!result?.detail) {
        let entityRow = this.rows.find((item) => item.id === id)
        for (const key in entity) {
          if (key in entityRow) entityRow[key] = entity[key]
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(
        urls.mainPartners,
        'POST',
        null,
        this.entityQuery()
      )
      if (!result?.detail) {
        const tmpEntity = this.newEntity
        this.rows.unshift({ ...{ id: result.id }, ...tmpEntity })
        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })

        this.newEntityToDefault()
      } else {
        this.isHideNewRowComponent = false
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async deleteRow(ids) {
      let idsLocal = typeof ids === 'object' ? ids : [ids]
      if (!idsLocal || !idsLocal.length) return

      this.$store.commit('setIsLoading', true)

      const deleteEntityQuery = (ids) => {
        let query = ''
        for (const id of ids) {
          query += `partner_ids=${id}&`
        }

        return query
      }

      let query = ''

      if (idsLocal?.length && idsLocal[0] === 'all') {
        query += query = `${this.queryToFetch(
          1,
          'all'
        )}&${this.queryToFilters()}&${
          this.queryToSort() & deleteEntityQuery(idsLocal)
        }`
      } else {
        query += deleteEntityQuery(idsLocal)
      }

      let result = await fetchData(urls.mainPartners, 'DELETE', null, query)
      if (!result?.detail) {
        for (const id of idsLocal) {
          const idx = this.rows.findIndex((item) => item.id === id)
          this.rows.splice(idx, 1)
        }
        this.selectedRows = []
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async exportToCSVRows(ids) {
      this.$store.commit('setIsLoading', true)

      const exportQuery = (ids) => {
        let query = '&'
        for (const id of ids) {
          query += `partner_ids=${id}&`
        }

        if (query === '&') {
          for (const row of this.rows) {
            query += `partner_ids=${row.id}&`
          }
        }

        if (this.options.isShowAllColumns) {
          query += `all_columns=true&`
        } else {
          query += `all_columns=false&`
        }

        return query
      }

      let query = ''

      if (ids?.length && ids[0] === 'all') {
        query += query = `${this.queryToFetch(
          1,
          'all'
        )}&${this.queryToFilters()}&${this.queryToSort()}`
      }

      query += exportQuery(ids)

      await fetchCSV(
        urls.mainPartnersExportToCsv,
        'GET',
        'partners',
        null,
        query
      )

      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      this.setQueryParams()

      this.$nextTick(() => {
        this.setHeightGridScrolableBlock()
      })
      if (this.isLoading) return

      this.$store.commit('setIsLoading', true)
      let query = ''
      let prevSearch_text = this.search_text.value

      query = `${this.queryToFetch()}&${this.queryToFilters()}&${this.queryToSort()}`

      let result = await fetchData(urls.mainPartners, 'GET', null, query)

      this.rows = result?.rows || []

      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },
  },
}
</script>
